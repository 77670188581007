import { ToastConfig } from '../models/toast.models'
import { Overlay, PositionStrategy } from '@angular/cdk/overlay'
import { ToastRef } from '../models/toast-ref.model'
import { BOTTOM_TOAST_CONNECTED_POSITIONS } from '../constants/toast.constants'

const getPosition = (lastToast: ToastRef, postion: 'top' | 'bottom'): string | undefined => {
  return lastToast?.isVisible() ? lastToast.getPosition()[postion] + 10 + 'px' : undefined
}

export const buildPositionStrategy = (
  overlay: Overlay,
  config: ToastConfig,
  lastToast: ToastRef
): PositionStrategy => {
  const globalPosition = overlay.position().global();
  const isToastVisible = lastToast?.isVisible();

  const getVerticalPosition = (primary: 'top' | 'bottom', secondary: 'top' | 'bottom') => 
    getPosition(lastToast, secondary) ?? config.margin;

  const handleGlobalPosition = (primary: 'top' | 'bottom', align: 'left' | 'right' | 'center') => {
    const verticalPosition = getVerticalPosition(primary, primary === 'top' ? 'bottom' : 'top');
    let position = globalPosition[primary](verticalPosition);

    if (align === 'center') {
      position = position.centerHorizontally();
    } else {
      position = position[align](config.margin);
    }

    return position;
  };

  const handleFlexiblePosition = () =>
    overlay.position().flexibleConnectedTo(lastToast.getElement()).withPositions(BOTTOM_TOAST_CONNECTED_POSITIONS);

  switch (config.position) {
    case 'top':
      return handleGlobalPosition('top', 'center');
    case 'bottom':
      return isToastVisible ? handleFlexiblePosition() : handleGlobalPosition('bottom', 'center');
    case 'left':
      return isToastVisible ? globalPosition.top(getVerticalPosition('top', 'bottom')).left(config.margin) : globalPosition.centerVertically().left(config.margin);
    case 'right':
      return isToastVisible ? globalPosition.top(getVerticalPosition('top', 'bottom')).right(config.margin) : globalPosition.centerVertically().right(config.margin);
    case 'top-left':
      return handleGlobalPosition('top', 'left');
    case 'bottom-right':
      return isToastVisible ? handleFlexiblePosition() : handleGlobalPosition('bottom', 'right');
    case 'bottom-left':
      return isToastVisible ? handleFlexiblePosition() : handleGlobalPosition('bottom', 'left');
    default:
      return handleGlobalPosition('top', 'right');
  }
};
