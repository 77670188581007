import { PLAYER_POSITIONS_EN, PLAYER_POSITIONS_ES } from 'src/app/constants/app.constants';
import { Player } from 'src/app/core/types/dashboard-selector.types';

/**
 * Retrieves selected players from the current list based on changes from the previous list.
 * @param previousPlayers Old array of players from the previous API call.
 * @param currentPlayers New array of players from the current API call.
 * @param previousSelectedPlayers The array of selected players.
 * @returns An array of selected players.
 */
export const getSelectedPlayers = (
  previousPlayers: Player[],
  currentPlayers: Player[],
  previousSelectedPlayers: Player[]
): Player[] => {
  if (!currentPlayers || currentPlayers.length == 0) {
    return [];
  }

  if (
    (previousPlayers && previousPlayers.length == 0) ||
    (previousSelectedPlayers && previousSelectedPlayers.length == 0)
  ) {
    return currentPlayers;
  }

  // Filter previous players based on selected team IDs
  const filteredPrevPlayers = getCurrentTeamPlayers(
    previousPlayers,
    currentPlayers
  );
  const filteredSelectedPlayers = getCurrentTeamPlayers(
    previousSelectedPlayers,
    currentPlayers
  );

  // Get added players that are in currentPlayers but not in previousPlayers
  const addedPlayers = getAddedPlayers(filteredPrevPlayers, currentPlayers);

  const selectedPlayers = [...filteredSelectedPlayers, ...addedPlayers];

  return selectedPlayers;
};

/**
 * Returns the players that are not present in the previous team but are present in the current team.
 * @param previousPlayers An array of players from the previous team.
 * @param currentPlayers An array of players from the current team.
 * @returns An array of players that have been added to the current team.
 */
export const getAddedPlayers = (
  previousPlayers: Player[],
  currentPlayers: Player[]
): Player[] => {
  const idSet = new Set(previousPlayers.map((player) => player.id));

  const addedPlayers = currentPlayers.filter((player) => !idSet.has(player.id));

  return addedPlayers;
};

/**
 * Removes players from the previous selected team that do not exist in the current team.
 * @param previousPlayers An array of players from the previous team.
 * @param currentPlayers An array of players from the current team.
 * @returns An array of players that belong to the current team.
 */
export const getCurrentTeamPlayers = (
  previousPlayers: Player[],
  currentPlayers: Player[]
): Player[] => {
  const idSet = new Set(currentPlayers.map((player) => player.id));

  const players = previousPlayers.filter((player) => idSet.has(player.id));
  return players;
};

/**
 * Returns the players in custom sorted order.
 * @param players An array of players.
 * @returns An array of players sorted in custom order.
 */

export const sortPlayers = (players: Player[], currentLanguage: string): Player[] => {
  if (!players || !Array.isArray(players)) {
    console.warn('sortPlayers called with invalid players array:', players);
    return [];
  }

  const positionOrder = getPositionOrder(currentLanguage);

  return players.sort((a, b) => {
    const positionComparison = comparePositions(a, b, positionOrder);
    if (positionComparison !== 0) return positionComparison;

    const numberComparison = compareNumbers(a, b);
    if (numberComparison !== 0) return numberComparison;

    return compareNames(a, b);
  });
};

const getPositionOrder = (language: string): string[] => {
  // Adjust positionOrder list to handle all languages
  return language === 'en' ? PLAYER_POSITIONS_EN : PLAYER_POSITIONS_ES;
};

const comparePositions = (a: Player, b: Player, positionOrder: string[]): number => {
  if (!a.positionDescription && b.positionDescription) return 1;
  if (a.positionDescription && !b.positionDescription) return -1;
  if (!a.positionDescription && !b.positionDescription) return 0;

  const positionA = positionOrder.indexOf(a.positionDescription?.trim().toLowerCase());
  const positionB = positionOrder.indexOf(b.positionDescription?.trim().toLowerCase());

  if (positionA === -1 && positionB === -1) return 0;
  if (positionA === -1) return 1;
  if (positionB === -1) return -1;

  return positionA - positionB;
};

const compareNumbers = (a: Player, b: Player): number => {
  if (a.number != null && b.number != null) return a.number - b.number;
  if (a.number == null && b.number != null) return 1;
  if (a.number != null && b.number == null) return -1;
  return 0;
};

const compareNames = (a: Player, b: Player): number => {
  if (a.name && b.name) return a.name.localeCompare(b.name);
  if (!a.name && b.name) return 1;
  if (a.name && !b.name) return -1;
  return 0;
};
