import { WidgetsConfig } from '../core/types/dashboard-structure.types';
import { SFMPitchArea, SFMMetrics } from '../core/types/summary-fieldmap.types';

export const WIDGETS_CONFIG: WidgetsConfig = {
  VA_CONFIG: {
    events: [],
    zoneConfiguration: {
      Columns: 6,
      Rows: 5,
    },
    startArea: [],
    endArea: [],
    showOpponent: false,
    urls: [
      {
        key: 'WIDGET_DATA',
        url: 'Event/filtered-events',
        type: 'POST',
        internal: true,
        cache: true,
        service: 'DEFAULT',
        params: {
          page: 1,
          pageSize: 100000
        }
      } 
    ]
  },
  SFM_CONFIG: {
    payload: {
      selectedTeams: [],
      selectedPlayers: [],
      selectedMatches: [],
      selectedEvents: [],
      zoneConfiguration: {
        rows: 5,
        columns: 6,
      },
      displayOptions: {
        basedOn: SFMPitchArea.startArea,
        metrics: [SFMMetrics.number],
      },
      selectedZones: [],
    },
    events: [],
    showOpponent: false,
   urls: [
      {
        key: 'WIDGET_DATA',
        url: 'EventSummary/detailed-event-summary',
        type: 'POST',
        internal: true,
        cache: true,
        service: 'DEFAULT',
      } 
    ]
  },
  EFM_CONFIG: {
    events: [],
    teams: [],
    players: [],
    matches: [],
    selectedEvents: [],
    zoneConfiguration: {
      Columns: 6,
      Rows: 5,
    },
    startArea: [],
    endArea: [],
    showOpponent: false,
    urls: [
      {
        key: 'WIDGET_DATA',
        url: 'Event/filtered-events',
        type: 'POST',
        internal: true,
        cache: true,
        service: 'DEFAULT',
        params: {
          page: 1,
          pageSize: 100000
        }
      } 
    ]
  },
  DT_TEAM_CONFIG: {
    payload: {
      matchesIds: [],
      teamsIds: [],
      metricTypeIds: [],
    },
    displayOptions: {
      matchPerimeter: 'datasetSelection',
      rowOptions: {
        individualGames: true,
        average: false,
        aggregate: false,
      },
    },
    urls: [
      {
        key: 'WIDGET_DATA',
        url: 'MatchMetrics',
        type: 'POST',
        internal: true,
        cache: true,
        service: 'DEFAULT',
      } 
    ]
  },
  DT_PLAYER_CONFIG: {
    matchesIds: [],
    playersIds: [],
    teamsIds: [],
    metricTypeIds: [],
    displayOptions: {
      matchPerimeter: 'datasetSelection',
      rowOptions: {
        individualGames: true,
        average: false,
        aggregate: false,
      },
    },
    urls: [
      {
        key: 'WIDGET_DATA',
        url: 'MatchMetrics/GetPlayerMatchesMetrics', 
        type: 'POST',
        internal: true,
        cache: true,
        service: 'DEFAULT',
      } 
    ]
  },
  TEAM_RANKING_CONFIG: {
    payload: {
      competitionId: 0,
      seasonId: 0,
      // TODO: Empty array after implementing match day selector
      matchDays: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
      ],
      metricTypeIds: [],
      highlightedTeams: [],
    },
    urls: [
      {
        key: 'WIDGET_DATA',
        url: 'TeamsRanking',
        type: 'POST',
        internal: true,
        cache: true,
        service: 'DEFAULT',
      } 
    ]
  },
  POSITIONING_FIELD_MAP_CONFIG: {
    payload: {
      "matches": [],
      "teamInformation": "",
      "phase": "",
      "zone": ""
    },
    url: [],
    ownTeam:{
      teamId:"",
      players:[]
    },
    opponentTeam:{
      teamId:"",
      players:[]
    },
  }
};
