import { createSelector, createFeatureSelector } from '@ngrx/store';
import { DashboardState, DashboardTypesState, DatasetSelectorState, EventSummaryRequestState, MatchEventsState } from './dashboard.reducer';
export interface AppState {
  dashboard: DashboardState;
  dashboardTypes: DashboardTypesState;
  matchEvents: MatchEventsState;
  eventSummary: EventSummaryRequestState;
  datasetSelectors: DatasetSelectorState;
}

// Directly select properties from the root state
const selectDashboardState = (state: AppState) => state.dashboard;
const selectDashboardTypesState = (state: AppState) => state.dashboardTypes;
const selectMatchEventsState = (state: AppState) => state.matchEvents;
const selectEventRequestSummaryState = (state: AppState) => state.eventSummary;
const selectDatasetSelectorsState = (state: AppState) => state.datasetSelectors;

export const selectDashboard = createSelector(
  selectDashboardState,
  (state: DashboardState) => state
);

export const selectAddDataSuccess = createSelector(
  selectDashboardState,
  (state: DashboardState) => {
    return { updatedId: state?.updatedId, addDataSuccess: state?.addDataSuccess }
  }
);

export const selectDashboardDetail = createSelector(
  selectDashboardState,
  (state: DashboardState) => {
    return { dashboard: state?.selected, loading: state?.loading }
  }
);

export const selectError = createSelector(
  selectDashboardState,
  (state: DashboardState) => state?.errors
);

export const selectDashboardTypes = createSelector(
  selectDashboardTypesState,
  (state: DashboardTypesState) => state
);


export const selectCompetitions = createSelector(
  selectMatchEventsState,
  (state: MatchEventsState) => state?.competitions
);

export const selectTeams = createSelector(
  selectMatchEventsState,
  (state: MatchEventsState) => state?.teams
);

export const selectPlayers = createSelector(
  selectMatchEventsState,
  (state: MatchEventsState) => state?.players
);

export const selectMatches = createSelector(
  selectMatchEventsState,
  (state: MatchEventsState) => state?.matches
);

export const selectEvents = createSelector(
  selectMatchEventsState,
  (state: MatchEventsState) => state?.events
);

export const selectEventRequestSummary = createSelector(
  selectEventRequestSummaryState,
  (state: EventSummaryRequestState) => state
);

export const selectDatasetSelectors = createSelector(
  selectDatasetSelectorsState,
  (state: DatasetSelectorState) => state
);

export const selectDatasetTeams = createSelector(
  selectDatasetSelectorsState,
  (state: DatasetSelectorState) => state?.teams
);

export const selectDatasetPlayers = createSelector(
  selectDatasetSelectorsState,
  (state: DatasetSelectorState) => state?.players
);

export const selectDatasetCompetition = createSelector(
  selectDatasetSelectorsState,
  (state: DatasetSelectorState) => state?.competitions
);

export const selectMetrics = createSelector(
  selectMatchEventsState,
  (state: MatchEventsState) => state?.metrics
);

export const selectTeamMetrics = createSelector(
  selectMatchEventsState,
  (state: MatchEventsState) => state.teamMetrics
);

export const selectPlayerMetrics = createSelector(
  selectMatchEventsState,
  (state: MatchEventsState) => state.playerMetrics
);
