import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PlaylistService } from 'src/app/services/playlist/playlist.service';
import {
  favouriteAddedPlaylist,
  deleteClip,
  deleteClipFailure,
  deleteClipSuccess,
  getPlaylists,
  loadPlaylistsFailure,
  loadPlaylistsSuccess,
  getUserPlaylists,
  loadUserPlaylistsFailure,
  loadUserPlaylistsSuccess,
} from './playlist.actions';
import { catchError, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../dashboard';
import { selectUserPlaylists } from './playlist.selectors';

@Injectable()
export class PlaylistEffects {
  getPlaylistEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getPlaylists),
      mergeMap((_action) =>
        this._playlistService.getPlaylists().pipe(
          map((data) => loadPlaylistsSuccess({ data })),
          catchError((error) => of(loadPlaylistsFailure({ error })))
        )
      )
    )
  );

  getUserPlaylistEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getUserPlaylists),
      withLatestFrom(this._store.select(selectUserPlaylists)),
      mergeMap(([action, state]) => {
        if (action.reload || state === undefined || state.length == 0) {
          return this._playlistService.getUserPlaylists(action.userId).pipe(
            map((data) => loadUserPlaylistsSuccess({ data })),
            catchError((error) => of(loadUserPlaylistsFailure({ error })))
          );
        } else {
          return of(loadUserPlaylistsSuccess({ data: state }))
        }
      })
    )
  );

  fetchPlaylistsOnFavouriteAdded$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favouriteAddedPlaylist),
      map(action => getPlaylists())
    )
  );
  deleteClipEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteClip), // Assuming deleteClip now accepts an array of IDs
      mergeMap(({ clipIds }) => 
        this._playlistService.removeClipsFromPlaylist(clipIds).pipe(
          map(() => {
            return deleteClipSuccess({ clipIds })
          }), // Pass the array of deleted IDs on success
          catchError((error) => of(deleteClipFailure({ error }))) // Dispatch failure action on error
        )
      )
    )
  );
  

  constructor(
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _playlistService: PlaylistService
  ) {}
}
