import { createReducer, on } from '@ngrx/store';
import * as DashboardAction from "./dashboard.actions"
import { Competition, Match, Player, Team } from 'src/app/core/types/dashboard-selector.types';
import { APIResponse } from 'src/app/core/types/spa.types';
import { EventTypeResponse } from 'src/app/core/types/event.types';

export interface DashboardState {
  addDataSuccess?: any;
  updatedId?: number;
  value: APIResponse<any>;
  selected: any;
  isFailed: boolean;
  isSuccess: boolean;
  reasons: any[];
  errors: any;
  successes: any[];
  loading: boolean;
}

export interface DashboardTypesState {
  data:  APIResponse<any>
}

export const initialState: DashboardState = {
  value: {
    items: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
    hasNextPage: false,
    hasPreviousPage: false
  },
  selected: null,
  isFailed: false,
  isSuccess: false,
  reasons: [],
  errors: [],
  successes: [],
  loading: false,
};

export const initialStateTypes: DashboardTypesState = {
  data: {
    items: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
    hasNextPage: false,
    hasPreviousPage: false
  }
};

export interface MatchEventsState {
  competitions:  APIResponse<Competition>,
  teams: APIResponse<Team>,
  players:  APIResponse<Player>,
  matches:  APIResponse<Match>,
  events: EventTypeResponse[],
  metrics: any[];
  teamMetrics: any[];
  playerMetrics: any[];
}

export const initialSelectorState = {
  items: [],
  page: 1,
  pageSize: 20,
  totalCount: 15,
  hasNextPage: false,
  hasPreviousPage: true,
  loading: false,
}

export const initialMatchEventsState: MatchEventsState = {
  competitions: { ...initialSelectorState },
  teams: { ...initialSelectorState },
  players: { ...initialSelectorState },
  matches: { ...initialSelectorState },
  events: [],
  metrics: [],
  teamMetrics: [],
  playerMetrics: []
};

export interface EventSummaryRequestState {
  teams: number[],
  players: number[],
  matches: number[],
  competitions?: any[],
}

export const initialEventSummaryRequestState: EventSummaryRequestState = {
  teams: [],
  players: [],
  matches: [],
  competitions: [],
};

export interface DatasetSelectorState {
  competitions: Competition[];
  teams: Team[];
  players: Player[];
  matches: Match[];
}

export const initialDatasetSelectorState: DatasetSelectorState = {
  competitions: [],
  teams: [],
  players: [],
  matches: [],
}

export const dashboardReducer = createReducer(
  initialState,
  on(DashboardAction.loadDashboardSuccess, (state, { data }) => ({
    ...state,
    value: {
      ...state.value,
      items: data.value.items,
      page: data.value.page,
      pageSize: data.value.pageSize,
      totalCount: data.value.totalCount,
      hasNextPage: data.value.hasNextPage,
      hasPreviousPage: data.value.hasPreviousPage
    },
    isFailed: data.isFailed,
    isSuccess: data.isSuccess,
    errors: data.errors,
    successes: data.successes,
    loading: false,
  })),
  on(DashboardAction.loadMoreDashboardSuccess, (state, { data }) => ({
    ...state,
    value: {
      ...state.value,
      items: [...state.value.items, ...data.value.items],
      page: data.value.page,
      pageSize: data.value.pageSize,
      totalCount: data.value.totalCount,
      hasNextPage: data.value.hasNextPage,
      hasPreviousPage: data.value.hasPreviousPage
    },
    isFailed: data.isFailed,
    isSuccess: data.isSuccess,
    errors: data.errors,
    successes: data.successes
  })),
  on(DashboardAction.loadDashboardFailure, (state, { error }) => ({
    ...state,
    value: {
      items: [],
      page: 0,
      pageSize: 0,
      totalCount: 0,
      hasNextPage: false,
      hasPreviousPage: false
    },
    isFailed: true,
    isSuccess: false,
    errors: [error],
    successes: [],
    loading: false,
  })),
  on(DashboardAction.addDashboard, (state) => ({ ...state, addDataSuccess: false })),
  on(DashboardAction.addDashboardSuccess, (state, { data }) => ({ ...state, addDataSuccess: true, updatedId: data })),
  on(DashboardAction.updateDashboard, (state) => ({ ...state, addDataSuccess: false })),
  on(DashboardAction.updateDashboardSuccess, (state, { data }) => ({ ...state, addDataSuccess: true, updatedId: data })),
  on(DashboardAction.updateDashboardStructure, (state) => ({ ...state, addDataSuccess: false })),
  on(DashboardAction.updateDashboardStructureSuccess, (state, { data }) => ({ ...state, addDataSuccess: true, updatedId: data })),
  on(DashboardAction.deleteDashboard, (state, { dashboardId }) => {
    return {
      ...state,
      value: {
        ...state.value,
        items: state.value.items.filter(item => item.id !== dashboardId),
        pageSize: state.value.pageSize - 1,
        totalCount: state.value.totalCount - 1
      }
    };
  }),
  on(DashboardAction.deleteDashboardSuccess, (state) => ({ ...state })),
  on(DashboardAction.loadDashboardDetailSuccess, (state, { data }) => ({ ...state, selected: data, loading: false })),
  on(DashboardAction.handleError, (state, { errors }) => ({ ...state, errors, loading: false })),
  on(DashboardAction.setDashboardsLoading, (state, {loading}) => ({ ...state, loading })),
);
export const dashboardTypesReducer = createReducer(
  initialStateTypes,
  on(DashboardAction.loadDashboardTypes, (state, { data }) => ({ ...state, data, loading: false, error: null }))
);


export const matchEventsReducer = createReducer(
  initialMatchEventsState,
  on(DashboardAction.loadCompetitionsSuccess, (state, { data }) => ({ ...state, competitions: { ...data, loading: false } })),
  on(DashboardAction.setCompetitionLoading, (state, { loading }) => ({ ...state, competitions: {  ...state.competitions, loading } })),
  on(DashboardAction.loadTeamsSuccess, (state, { data }) => ({ ...state, teams: { ...data, loading: false } })),
  on(DashboardAction.setTeamLoading, (state, { loading }) => ({ ...state, teams: { ...state.teams, loading } })),
  on(DashboardAction.loadPlayersSuccess, (state, { data }) => ({ ...state, players: { ...data, loading: false } })),
  on(DashboardAction.setPlayerLoading, (state, { loading }) => ({ ...state, players: { ...state.players, loading } })),
  on(DashboardAction.loadMatchesSuccess, (state, { data }) => ({ ...state, matches: { ...data, oading: false } })),
  on(DashboardAction.setMatchLoading, (state, { loading }) => ({ ...state, matches: { ...state.matches, loading } })),
  on(DashboardAction.loadEventsSuccess, (state, { data }) => ({ ...state, events: data })),
  on(DashboardAction.loadMetricsSuccess, (state, { data }) => ({ ...state, metrics: data })),
  on(DashboardAction.loadTeamMetricsSuccess, (state, { data }) => ({ ...state, teamMetrics: data })),
  on(DashboardAction.loadPlayerMetricsSuccess, (state, { data }) => ({ ...state, playerMetrics: data }))
);

export const eventSummaryReducer = createReducer(
  initialEventSummaryRequestState,
  on(DashboardAction.updateTeams, (state, { data }) => ({ ...state, teams: data })),
  on(DashboardAction.updatePlayers, (state, { data }) => ({ ...state, players: data })),
  on(DashboardAction.updateMatches, (state, { data }) => ({ ...state, matches: data })),
  on(DashboardAction.updateEvents, (state, { data }) => ({ ...state, events: data })),
  on(DashboardAction.updateCompetitions, (state, { data }) => ({ ...state, competitions: data }))
);

export const datasetSelectorReducer = createReducer(
  initialDatasetSelectorState,
  on(DashboardAction.updateSelectorCompetitions, (state, { data }) => ({ ...state, competitions: data })),
  on(DashboardAction.updateSelectorTeams, (state, { data }) => ({ ...state, teams: data })),
  on(DashboardAction.updateSelectorPlayers, (state, { data }) => ({ ...state, players: data })),
  on(DashboardAction.updateSelectorMatches, (state, { data }) => ({ ...state, matches: data })),
);