import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Playlist } from 'src/app/core/types/playlist.types';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  constructor(private _http: HttpClient) { }

  getPlaylists(): Observable<Playlist[]> {
    return this._http.get<Playlist[]>(`${environment.PLAYLIST_URL}/Playlist`);
  }

  addClipsToPlaylist(playlistClips: any): Observable<any> {
    return this._http.post<any>(`${environment.PLAYLIST_URL}/PlaylistItem`, playlistClips)
  }

  removeClipsFromPlaylist(playlistClips: string[]): Observable<any> {
    return this._http.request<any>('delete', `${environment.PLAYLIST_URL}/PlaylistItem`, {
      body: playlistClips, // Include the array in the body
    });
  }
  

  createPlaylist(payload: any): Observable<any> {
    return this._http.post<any>(`${environment.PLAYLIST_URL}/Playlist`, payload);
  }

  markFavorite(payload: any): Observable<any> {
    return this._http.post<any>(`${environment.PLAYLIST_URL}/PlaylistItem/playlist-favorite`, payload);
  }

  getUserPlaylists(userId: string): Observable<any> {
    return this._http.get<any>(`${environment.PLAYLIST_URL}/Playlist/get-by-userid/${userId}`);
  }

  deletePlaylist(playlistId: number): Observable<any> {
    return this._http.delete(`${environment.PLAYLIST_URL}/Playlist/${playlistId}`);
  }

  renamePlaylist(payload: any): Observable<any> {
    return this._http.put(`${environment.PLAYLIST_URL}/Playlist`, payload);
  }
}
