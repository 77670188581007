export const environment = {
  production: false,
  API_URL: 'https://analysis-backend-api-dv.mediacoach.es/api/v1',
  PLAYLIST_URL: 'https://playlist-api-dv.mediacoach.es/api/v1',
  idsConfig: {
    authority: 'https://id-dv.mediacoach.es',
    client_id: '5ad3ed5B-0ead-4d12-9da3-35cd667590b8',
    redirect_uri: `${window.location.origin}/oidc/callback.html`,
    silent_redirect_uri: `${window.location.origin}/oidc/silent.html`,
    response_type: "code",
    scope: "openid streams-vod sportiananalysis-api.tokens sportiananalysis-api-accountmanager tagmanager-api avgpositions-api",
    post_logout_redirect_uri: `${window.location.origin}/index.html`,
  }
};
