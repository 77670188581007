import { Component, Optional } from '@angular/core';
import { DialogConfig, DialogRef } from 'src/app/components/dialog/models';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SUPPORTED_LANGUAGES } from 'src/app/constants';
import { ToastService } from 'src/app/components/toast/services/toast.service';
import { ToastConfig, ToastData } from 'src/app/components/toast/models/toast.models';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrl: './user-profile-dialog.component.scss'
})
export class UserProfileDialogComponent {
  languages = SUPPORTED_LANGUAGES;
  profileForm!: FormGroup;
  userProfileDetails!: any;
  savedLanguageText!: string;

  constructor(
    private formBuilder: FormBuilder,
    private _translate: TranslateService,
    private _dialogRef: DialogRef,
    private toastService: ToastService,
    @Optional() private _dialogConfig: DialogConfig<any>,
    private authService: AuthService
  ) {
    this.userProfileDetails = this._dialogConfig?.data.userProfileDetails;

    // Initialize form
    this.profileForm = this.formBuilder.group({
      name: [this.userProfileDetails?.name, [Validators.required, Validators.minLength(2), Validators.pattern(/^[a-zA-Z\s]+$/)]],
      surname: [this.userProfileDetails?.surname,[ Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],
      email: [this.userProfileDetails?.email, [Validators.required, Validators.email]],
      language: [this.getStoredLanguage(), Validators.required] // Use the stored language if available
    });

    // Disable fields
    this.profileForm.get('email')?.disable();
  }

  // Retrieve the stored language from sessionStorage or default to current user's language
  getStoredLanguage(): string {
    return this._translate.currentLang || this.userProfileDetails?.language || 'en';
  }

  // Update the language and store it in sessionStorage
  updateLanguage(language: string) {
    this._translate.use(language);

  }

  // Handle form submission
  onSubmit() {
    if (this.profileForm.invalid) {
      return;
    }

    let { name, surname, language } = this.profileForm.value;

    this.updateLanguage(language);
    language = SUPPORTED_LANGUAGES.find(lang => lang.key === language)?.altName;
    this.authService.saveUserProfile({ name, surname, language }).subscribe({
      next: () => {this.showMessage()
        this._dialogRef.close(true);
      },
      error: (err) => {console.error('Save failed', err)
      this._dialogRef.close(true);
      }
    });
  }

  // Show Message on profile changes
  showMessage() {
    // Fetch the translated text asynchronously to ensure the translation is available
    this._translate.get('toasts.profileSave').subscribe((translatedText: string) => {
      this.savedLanguageText = translatedText;

      const toastData: ToastData = {
        message: this.savedLanguageText,
      };
      const toastConfig: ToastConfig = {
        type: 'success',
        position: 'top-right',
      };
      this.toastService.show(toastData, toastConfig);
    });
  }

  get nameControl() {
    return this.profileForm.get('name');
  }
  
  get surnameControl() {
    return this.profileForm.get('surname');
  }
  
  getErrorMessage(controlName: string): string {
    const control = this.profileForm.get(controlName);
  
    if (control?.hasError('required')) {
      return this._translate.instant('form.required');
    } else if (control?.hasError('minlength')) {
      return this._translate.instant('form.minLength');
    } else if (control?.hasError('pattern')) {
      return this._translate.instant('form.invalidCharacters');
    }
  
    return '';
  }

  onCancel() {
    this._dialogRef.close(false);
  }
}
