import { WidgetCard } from '../core/types/dashboard-structure.types';

// * Note: The `key` in each widget should match the corresponding key defined in the `WidgetsConfig` interface, without the 'CONFIG' suffix (e.g., 'VA' for 'VA_CONFIG').
export const WIDGETS: WidgetCard[] = [
  {
    titleKey: 'widgets.videoAnalysis',
    title: 'widgets.videoAnalysis',
    key: 'VA',
    category: 'Defensive',
    position: 1,
    widgetType: 'video-analysis',
    currentImage: 'assets/images/widgets/video_analysis.svg',
    defaultImage: 'assets/images/widgets/video_analysis.svg',
    selectedImage: 'assets/images/widgets/video_analysis_active.svg',
    selected: false,
    structure: '12x12',
  },
  {
    titleKey: 'widgets.eventSummary',
    title: 'widgets.eventSummary',
    key: 'SFM',
    category: 'Physical',
    position: 2,
    widgetType: 'summary-fieldmap',
    currentImage: 'assets/images/widgets/event_summary.svg',
    defaultImage: 'assets/images/widgets/event_summary.svg',
    selectedImage: 'assets/images/widgets/event_summary_active.svg',
    selected: false,
    structure: '8x4',
  },

  {
    titleKey: 'widgets.eventFieldmap',
    title: 'widgets.eventFieldmap',
    key: 'EFM',
    category: 'Offensive - Creation',
    position: 3,
    widgetType: 'event-fieldmap',
    currentImage: 'assets/images/widgets/event_fieldmap.svg',
    defaultImage: 'assets/images/widgets/event_fieldmap.svg',
    selectedImage: 'assets/images/widgets/event_fieldmap_active.svg',
    selected: false,
    structure: '8x4',
  },
  {
    titleKey: 'widgets.dataTable',
    title: 'widgets.dataTable',
    key: 'DT_TEAM',
    category: 'Defensive',
    position: 4,
    widgetType: 'data-table-teams',
    currentImage: 'assets/images/widgets/data_table_widget.svg',
    defaultImage: 'assets/images/widgets/data_table_widget.svg',
    selectedImage: 'assets/images/widgets/data_table_widget_active.svg',
    selected: false,
    structure: '8x4',
  },
  {
    titleKey: 'widgets.dataTablePlayer',
    title: 'widgets.dataTablePlayer',
    key: 'DT_PLAYER',
    category: 'Defensive',
    position: 5,
    widgetType: 'data-table-player',
    currentImage: 'assets/images/widgets/data_table_widget.svg',
    defaultImage: 'assets/images/widgets/data_table_widget.svg',
    selectedImage: 'assets/images/widgets/data_table_widget_active.svg',
    selected: false,
    structure: '8x4',
  },
  {
    titleKey: 'widgets.dataTableRanking',
    title: 'widgets.dataTableRanking',
    key: 'TEAM_RANKING',
    category: 'Defensive',
    position: 5,
    widgetType: 'team-ranking',
    currentImage: 'assets/images/widgets/ranking_widget.svg',
    defaultImage: 'assets/images/widgets/ranking_widget.svg',
    selectedImage: 'assets/images/widgets/ranking_widget_active.svg',
    selected: false,
    structure: '8x4',
  },
  {
    titleKey: 'widgets.positioningFieldMap',
    title: 'widgets.positioningFieldMap',
    key: 'POSITIONING_FIELD_MAP',
    category: 'Defensive',
    position: 5,
    widgetType: 'positioning-field-map',
    currentImage: 'assets/images/widgets/positioning_field_map.svg',
    defaultImage: 'assets/images/widgets/positioning_field_map.svg',
    selectedImage: 'assets/images/widgets/positioning_field_map_active.svg',
    selected: false,
    structure: '8x4',
  }
];
