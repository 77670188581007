import { environment } from '@environments/environment';
import {
  WidgetUrl,
  ServiceType,
  WidgetKey,
} from 'src/app/core/types/dashboard-structure.types';

/**
 * A mapping of service types to their respective base URLs.
 */
export const serviceUrlMap: Record<ServiceType, string> = {
  DEFAULT: environment.API_URL,
  PLAYLIST: environment.PLAYLIST_URL,
};

/**
 * Finds a WidgetUrl object from an array based on a provided key.
 * @param widgetUrls - The array of WidgetUrl objects.
 * @param key - The key to search for in the widgetUrls array.
 * @returns The WidgetUrl object if found, otherwise undefined.
 */
export const findUrlByKey = (
  widgetUrls: WidgetUrl[],
  key: WidgetKey
): WidgetUrl | undefined => {
  return widgetUrls.find((url) => url.key === key);
};

/**
 * Constructs the full URL for the widget data based on the widgetUrls array.
 * This function uses the serviceUrlMap to retrieve the base URL and combines it with the path.
 * @param widgetUrls - The array of WidgetUrl objects.
 * @returns The full URL with parameters for the widget data if found, otherwise undefined.
 */
export const getWidgetDataUrl = (
  widgetUrls: WidgetUrl[]
): WidgetUrl | undefined => {
  const widgetDataUrl = findUrlByKey(widgetUrls, 'WIDGET_DATA');

  if (!widgetDataUrl) {
    return undefined; // If no matching URL is found, return undefined
  }

  // Get the base URL from the serviceUrlMap based on the service key
  const baseUrl = serviceUrlMap[widgetDataUrl.service];
  if (!baseUrl) {
    return undefined; // Return undefined if the service URL is not found
  }

  // Combine the base URL with the widget's relative URL
  const apiUrl = `${baseUrl}/${widgetDataUrl.url}`;

  // Return the widget URL object with the full URL
  return { ...widgetDataUrl, url: apiUrl };
};

/**
 * Replaces dynamic parameters in the URL with values from the params object.
 * This allows replacing placeholders in the URL with actual values.
 * @param url - The URL string with placeholders (e.g., {{userId}}).
 * @param params - An object containing the parameter key-value pairs to replace in the URL.
 * @returns The updated URL with parameters replaced.
 */
export const replaceUrlParams = (
  url: string,
  params: Record<string, any>
): string => {
  // Iterate over each key in the params object and replace the corresponding placeholder in the URL
  Object.keys(params).forEach((paramKey) => {
    // Create a regex to match the placeholder (e.g., {{userId}})
    const placeholder = `{{${paramKey}}}`;

    // Get the corresponding value from the params object
    const value = params[paramKey];

    // Replace all occurrences of the placeholder in the URL
    url = url.replace(new RegExp(placeholder, 'g'), value.toString());
  });

  return url;
};

/**
 * Type guard to check if the given object is a valid WidgetUrl.
 *
 * @param widgetUrl - The object to be checked.
 * @returns True if the object matches the `WidgetUrl` structure, otherwise false.
 */
export const isWidgetUrl = (widgetUrl: any): widgetUrl is WidgetUrl => {
  return (
    typeof widgetUrl === 'object' &&
    widgetUrl !== null &&
    typeof widgetUrl.url === 'string' &&
    typeof widgetUrl.key === 'string' &&
    typeof widgetUrl.internal === 'boolean' &&
    typeof widgetUrl.type === 'string' &&
    typeof widgetUrl.cache === 'boolean' &&
    typeof widgetUrl.service === 'string'
  );
};

/**
 * Type guard to check if the given object is an array of WidgetUrl objects.
 *
 * @param widgetUrls - The object to be checked.
 * @returns True if the object is an array of valid `WidgetUrl` objects, otherwise false.
 */
export const isWidgetUrlArray = (
  widgetUrls: any
): widgetUrls is WidgetUrl[] => {
  // Check if widgetUrls is an array and all elements in the array are valid WidgetUrl objects
  return Array.isArray(widgetUrls) && widgetUrls.every(isWidgetUrl);
};
