import { createReducer, on } from '@ngrx/store';
import {
  addUserPlaylist,
  loadPlaylistsFailure,
  loadPlaylistsSuccess,
  loadUserPlaylistsFailure,
  loadUserPlaylistsSuccess,
  selectClip,
  selectPlaylist,
  deleteClipSuccess,
  deleteClipFailure,
} from './playlist.actions';
import { Playlist, PlaylistClip } from 'src/app/core/types/playlist.types';

export interface PlaylistState {
  data: Playlist[];
  selectedPlaylist: Playlist | undefined;
  selectedClip: PlaylistClip | undefined;
  error: any;
}

export interface UserPlaylistState {
  data: any[] | undefined;
  error: any;
}

// * =========================================================================================================
// * Reducers for managing the state of all playlists displayed in the playlist module.
// * =========================================================================================================

export const initialPlaylistState: PlaylistState = {
  data: [],
  selectedPlaylist: undefined,
  selectedClip: undefined,
  error: null,
};

export const playlistReducer = createReducer(
  initialPlaylistState,
  // Handle playlist retrieval success
  on(loadPlaylistsSuccess, (state, { data }) => ({
    ...state,
    data,
    error: null,
  })),
  // Handle playlist retrieval failure
  on(loadPlaylistsFailure, (state, { error }) => ({
    ...state,
    error,
    data: [],
  })),
  // Handle playlist selection
  on(selectPlaylist, (state, { playlist }) => ({
    ...state,
    selectedPlaylist: playlist,
  })),
  // Handle clip selection
  on(selectClip, (state, { clip }) => ({
    ...state,
    selectedClip: clip,
  })),
  // Handle successful deletion of clips
  on(deleteClipSuccess, (state, { clipIds }) => ({
    ...state,
    selectedPlaylist: state.selectedPlaylist
      ? {
          ...state.selectedPlaylist,
          playlistDetails: state.selectedPlaylist.playlistDetails.filter(
            (clip) => !clipIds.includes(clip.id)
          ),
          clipsCount: state.selectedPlaylist.clipsCount-clipIds.length
        }
      : undefined,
  })),
  
  // Handle failure during deletion of clips
  on(deleteClipFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

// * =========================================================================================================
// * Reducers for managing the user's playlist state. This state is responsible for handling
// * the addition of clips to the user's playlist
// * =========================================================================================================

export const initialUserPlaylistState: UserPlaylistState = {
  data: undefined,
  error: null,
};

export const userPlaylistReducer = createReducer(
  initialUserPlaylistState,
  on(loadUserPlaylistsSuccess, (state, { data }) => ({
    ...state,
    data,
    error: null,
  })),
  on(loadUserPlaylistsFailure, (state, { error }) => ({
    ...state,
    error,
    data: undefined,
  })),
  on(addUserPlaylist, (state, { playlist }) => ({
    ...state,
    error: null,
    data: state.data ? [...state.data, playlist] : [playlist],
  }))
);
