import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrl: './change-password-dialog.component.scss'
})
export class ChangePasswordDialogComponent {
  idsURL = environment.idsConfig.authority;
  URLChangePassword!: SafeResourceUrl;

  constructor(private readonly sanitizer: DomSanitizer) {
    // Constructed URL for the Change Password page
    const url = `${this.idsURL}/Manage/ChangePassword?embedded=true`

    // NOSONAR: This use of bypassSecurityTrustResourceUrl is safe. Bypassing Angular's built-in security check for resource URLs:
    // This URL is considered safe because:
    // 1. The base URL (this.idsURL) is a constant defined within the application 
    //    and not influenced by external or user inputs.
    // 2. The endpoint '/Manage/ChangePassword' is a well-known, trusted part of 
    //    our application's backend.
    // 3. The query parameter 'embedded=true' is static and does not introduce 
    //    any security vulnerabilities.
    // Given these conditions, we are confident that the URL is safe to use.
    this.URLChangePassword = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
