import { createReducer, on } from '@ngrx/store';
import { User } from 'src/app/core/types/Auth';
import { setUser } from './user.actions';

export interface UserState {
  user: User | undefined;
}

export const initialUserState: UserState = {
  user: undefined,
};

export const userReducer = createReducer(
  initialUserState,
  on(setUser, (state, { user }) => ({ ...state, user }))
);
