import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, expand, map } from 'rxjs';
import { APIParams, DashboardNewElement } from '../../core/types/spa.types';
import { DashboardStructurePayload } from 'src/app/core/types/dashboard-structure.types';
import { SMFPayload, SMFResponse } from 'src/app/core/types/summary-fieldmap.types';
import { sortPlayers } from 'src/app/shared/utils/selector.utils';
import { TranslateService } from '@ngx-translate/core';
import { TeamRankingResponse } from 'src/app/core/types/ranking.types';
@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor(private http: HttpClient,private translate: TranslateService) { }

  getAllDashboard(apiParams: APIParams): Observable<any> {
    return this.http
      .get<any>(`${environment.API_URL}/Dashboards?sortBy=${apiParams.sortBy}&sortOrder=${apiParams.sortOrder}&page=
      ${apiParams.page}&pageSize=${apiParams.pageSize}`);
  }
  getAllDashboardTypes(): Observable<any> {
    return this.http.get<any>(
      `${environment.API_URL}/DashboardTypes?sortBy=name&sortOrder=asc&page=1&pageSize=5`
    );
  }

  addDashboard(payload: DashboardNewElement): Observable<any> {
    return this.http.post<any>(`${environment.API_URL}/Dashboards`, payload);
  }

  getDashboardDetail(dashboardId: number): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}/Dashboards/${dashboardId}`)
  }

  deleteDashboard(dashboardId: number): Observable<any> {
    return this.http.delete<any>(
      `${environment.API_URL}/Dashboards/${dashboardId}`
    );
  }

  updateDashboard(payload: DashboardNewElement): Observable<any> {
    return this.http.put<any>(`${environment.API_URL}/Dashboards`, payload);
  }

  updateDashboardStructure(payload: DashboardStructurePayload): Observable<any> {
    return this.http.put<any>(`${environment.API_URL}/Dashboards/structure`, payload);
  }

  getAllCompetitions(): Observable<any> {
    const pageSize = 50;
    const page = 1;
    return this.http
      .get<any>(
        `${environment.API_URL}/Competition?page=${page}&pageSize=${pageSize}`
      )
      .pipe(
        expand((response) => {
          if (response.hasNextPage) {
            return this.fetchAll(page, response.totalCount, '', 'Competition', 'get');
          } else {
            return [];
          }
        })
      );
  }

  // TODO Important: Refine pagination logic for Teams and Players
  getAllTeams(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.API_URL}/Teams?page=1&pageSize=50`,
      payload
    );
  }

  getAllPlayers(payload: any): Observable<any> {
    const pageSize = 10000;
    const page = 1;
    
    return this.http
      .post<any>(
        `${environment.API_URL}/Players?page=${page}&pageSize=${pageSize}`,
        payload
      )
      .pipe(
        expand((response) => {
          if (response.hasNextPage) {
            return this.fetchAll(page, response.totalCount, payload, 'Players', 'post');
          } else {
            return [];
          }
        }),
        map((response) => {
          response.items=sortPlayers(response.items, this.translate.currentLang)
          return response;
        })
      );
  }

  fetchAll(
    page: number,
    pageSize: number,
    payload: any,
    apiName: string,
    method: string
  ): Observable<any> {
    switch (method.toLowerCase()) {
      case 'post':
        return this.http.post<any>(
          `${environment.API_URL}/${apiName}?page=${page}&pageSize=${pageSize}`,
          payload
        );
      case 'get':
        return this.http.get<any>(
          `${environment.API_URL}/${apiName}?page=${page}&pageSize=${pageSize}`
        );
      default:
        throw new Error('Unsupported HTTP method');
    }
  }
  // TODO Important: Refine pagination logic for Teams and Players

  // TODO: Refine pagination for Matches
  getAllMatches(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.API_URL}/Matches?page=1&pageSize=10000`,
      payload
    ).pipe(
      map((response) => {
        response.items.sort((a: any, b: any) => {
          return new Date(b.scheduled).getTime() - new Date(a.scheduled).getTime();
        })
        return response;
      })
    );
  }

  getEventSummaryFieldMap(payload: SMFPayload): Observable<SMFResponse> {
    return this.http.post<any>(
      `${environment.API_URL}/EventSummary/detailed-event-summary`,
      payload
    );
  }

  getMetrics(metricType: string): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}/MetricType?type=${metricType}`);
  }

  getTeamsRanking(payload: any): Observable<TeamRankingResponse> {
    return this.http.post<any>(
      `${environment.API_URL}/TeamsRanking`,
      payload
    );
  }
}
