export const generateUniqueId = (prefix: string = 'spa') => {
  // Get the current timestamp
  const timestamp = new Date().getTime();
  // Combine the prefix, timestamp, and random number to create a unique ID
  const uniqueId = `${prefix}-${timestamp}`;

  return uniqueId;
};

export const buildSelectedEventName = (event: any): string => {
  // Initialize an array to hold the parts of the title.
  const titleParts: string[] = [];

  // Add player name if available.
  if (event?.playerInfo?.name) {
    titleParts.push(event.playerInfo.name);
  }

  // Add match teams if both home and away teams are available.
  if (
    event?.matchInfo?.homeTeamShortName &&
    event?.matchInfo?.awayTeamShortName
  ) {
    titleParts.push(
      `${event.matchInfo.homeTeamShortName} - ${event.matchInfo.awayTeamShortName}`
    );
  }

  // Add event type name if available.
  if (event?.eventTypeInfo?.name) {
    titleParts.push(event.eventTypeInfo.name);
  }

  // Add additional event text if available.
  if (event?.text) {
    titleParts.push(event.text);
  }

  // Join all parts with " / " separator and return the result.
  return titleParts.join(' / ');
};
