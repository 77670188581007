import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PlaylistState, UserPlaylistState } from './playlist.reducer';

export const selectPlaylistState =
  createFeatureSelector<PlaylistState>('playlist');

export const selectPlaylists = createSelector(
  selectPlaylistState,
  (state: PlaylistState) => state.data
);

export const selectPlaylistError = createSelector(
  selectPlaylistState,
  (state: PlaylistState) => state.error
);

export const selectSelectedPlaylist = createSelector(
  selectPlaylistState,
  (state: PlaylistState) => state.selectedPlaylist
);

export const selectSelectedClip = createSelector(
  selectPlaylistState,
  (state: PlaylistState) => state.selectedClip
);

export const selectUserPlaylistState =
  createFeatureSelector<UserPlaylistState>('userPlaylist');

export const selectUserPlaylists = createSelector(
  selectUserPlaylistState,
  (state: UserPlaylistState) => state.data
);

export const selectUserPlaylistError = createSelector(
  selectUserPlaylistState,
  (state: UserPlaylistState) => state.error
);
