import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@spa-state';
import { MenuComponent, MenuItemEvent } from '@sportiananalysis/ui';
import { Subject, takeUntil } from 'rxjs';
import { DialogRef } from 'src/app/components/dialog/models';
import { DialogService } from 'src/app/components/dialog/services/dialog.service';
import { SUPPORTED_LANGUAGES } from 'src/app/constants';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ChangePasswordDialogComponent } from 'src/app/shared/dialogs/change-password-dialog/change-password-dialog.component';
import { UserProfileDialogComponent } from 'src/app/shared/dialogs/user-profile-dialog/user-profile-dialog.component';
import { getUserPlaylists } from 'src/app/state/playlist';
import { setUser } from 'src/app/state/user';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, AfterViewInit, OnDestroy {
  languages = SUPPORTED_LANGUAGES;
  userProfileDetails!: any;
  fullName!: string;
  initials!: string;
  menuItems = [
    {
      id: 'profile',
      label: '',
      icon: './assets/images/icons/profile.svg',
    },
    {
      id: 'change_password',
      label: '',
      icon: './assets/images/icons/change_password.svg',
    },
    {
      id: 'logout',
      label: '',
      icon: './assets/images/icons/logout.svg',
    },
  ];

  @ViewChild(MenuComponent) menu: MenuComponent | undefined;
  @Output() menuAction = new EventEmitter<MenuItemEvent>();
  dialogRef!: DialogRef;

  private _unsubscribeAll$: Subject<void> = new Subject<void>();

  constructor(
    private _translate: TranslateService,
    private authService: AuthService,
    private _dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _store: Store<AppState>
  ) {}

  ngOnInit(): void {
    // Load user asynchronously and set full name and initials
    this.fetchUserProfile();

    // Listen to language changes for translation re-rendering
    this._translate.onLangChange
      .pipe(takeUntil(this._unsubscribeAll$))
      .subscribe(() => {
        this.translateMenuItems(); // Re-translate the menu items on language change
        // Manually detect changes after translation
        this.cdr.detectChanges();
      });
  }

  fetchUserProfile() {
    this.authService.getUserProfile().subscribe((user) => {
      if (user) {
        this._store.dispatch(setUser({ user }));
        this.userProfileDetails = { ...user };
        this.fullName = `${user.name} ${user.surname}`;
        this.initials = this.getInitials(this.fullName);
        this.userProfileDetails.initials = this.initials;
        if (user.language) {
          const lang = user.language.split('-');
          this._translate.use(lang[0]);
        } else {
          this._translate.use('en');
        }
        this.fetchUserPlaylist(user.userId)
        // Manually detect changes to prevent ExpressionChangedAfterItHasBeenCheckedError
        this.cdr.detectChanges();
      }
    });
  }

  ngAfterViewInit(): void {
    // Translate menu items once the view is initialized
    this.translateMenuItems();
    // Manually trigger change detection
    this.cdr.detectChanges();
  }

  // Helper function to translate menu items
  private translateMenuItems(): void {
    this.menuItems.forEach((item) => {
      item.label = this._translate.instant('form.' + item.id);
    });
  }

  onAction(event: MenuItemEvent) {
    switch (event.item.id) {
      case 'profile':
        this.openProfile();
        break;
      case 'change_password':
        this.openPassword();
        break;
      case 'logout':
        this.onLogoutClick();
        break;

      default:
        break;
    }
  }

  openProfile() {
    this.dialogRef = this._dialogService.open(
      UserProfileDialogComponent,
      this.getSettingConfig()
    );
    this.dialogRef.onClose.subscribe(() => {
      this.fetchUserProfile();
    });
  }

  getSettingConfig() {
    return {
      header: this._translate.instant('form.profile'),
      width: '540px',
      draggable: false,
      headerIcon: './assets/images/icons/profile.svg',
      modal: true,
      contentStyleClass: 'p-0 overflow-hidden',
      data: {
        userProfileDetails: this.userProfileDetails,
      },
    };
  }

  openPassword() {
    const changePasswordDialogConfig = {
      header: this._translate.instant('form.change_password'),
      width: '540px',
      draggable: false,
      headerIcon: './assets/images/icons/lock_open.svg',
      modal: true,
      contentStyleClass: 'p-0 overflow-hidden',
    };
    this.dialogRef = this._dialogService.open(
      ChangePasswordDialogComponent,
      changePasswordDialogConfig
    );
    this.dialogRef.onClose.subscribe((result) => {});
  }

  getInitials(fullName: string): string {
    if (!fullName) return ''; // Return an empty string if input is empty or undefined

    // Split the full name by spaces and filter out any empty strings
    const nameParts = fullName.split(' ').filter(Boolean);

    // Map over each part, take the first letter, and join them together in uppercase
    const initials = nameParts.map((name) => name[0].toUpperCase()).join('');

    return initials;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
  }

  onLogoutClick() {
    const firstSegment = this.activatedRoute.snapshot.firstChild;
    this.authService.triggerLogout();
    if (
      !this.router.url.includes('dashboards/') &&
      firstSegment?.routeConfig?.path !== ':id'
    ) {
      this.authService.logout();
    }
  }

  fetchUserPlaylist(userId: string) {
    this._store.dispatch(getUserPlaylists({ userId, reload: true }));
  }
}
