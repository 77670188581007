import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

export const authGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);

  const user = await authService.getUser();
  if (user) {
    return true; // User is authenticated
  } else {
    await authService.login(); // Redirect to login
    return false; // User is not authenticated
  }
};